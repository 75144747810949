import { useState, useEffect, useRef } from "react";
import { Row, Col, Button, Spinner, Form, InputGroup } from "react-bootstrap";
import info from "../assets/info.svg";
import newIcon from "../assets/new.gif";
import morphysIcon from "../assets/morphys.gif";
import morphysLogo from "../assets/morphys-logo.webp";
import FlowtyArt from "../assets/Age_Baby.gif";
import openLink from "../assets/open-link-w.svg";
import unchained from "../assets/age_0.jpeg";

import {
  getAllowanceInkMickey,
  setAllowanceInkMickey,
  mintMickeyArt,
  getInkArtSupply,
  setAllowanceInkMorphys,
  getAllowanceInkMorphys,
} from "../model/Blockchain";

function Shop({
  web3,
  address,
  inkBalance,
  flowtyStaked,
  flowtyOwned,
  onMorphyMinted,
  isMobile,
}) {
  const [isMinted, setIsMinted] = useState(false);
  const [waitApprove, setWaitApprove] = useState(false);
  const [waitMint, setWaitMint] = useState(false);
  const [allowance, setAllowance] = useState(0);
  // const [allowanceMorphys, setAllowanceMorphys] = useState(0);
  const [amount, setAmount] = useState(1);
  const [amountPossible, setAmountPossible] = useState(1);
  const [validated, setValidated] = useState(false);

  function onInputchange(event) {
    setAmount(event.target.value);
  }
  const [artSupply, setArtSupply] = useState(0);

  const setAllowanceInkArt = () => {
    setWaitApprove(true);
    setAllowanceInkMickey(web3, address, 100000, () => {
      setWaitApprove(false);
      setAllowance(10000);
    });
  };

  const mintArt = () => {
    setWaitMint(true);
    mintMickeyArt(web3, address, 0, amount, () => {
      setWaitMint(false);
      // setArtSupply(artSupply + 1);
      setIsMinted(true);
    });
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     const data = await getMorphysOwned(address);
  //     setAmountPossible(flowtyStaked * 3 + flowtyOwned - data);
  //   };
  //   fetchData().catch(console.error);
  // }, [address, flowtyStaked, flowtyOwned]);

  useEffect(() => {
    const fetchData = async () => {
      const data = await getAllowanceInkMickey(address);
      const supply = await getInkArtSupply(0);
      setArtSupply(supply);
      setAllowance(data);
    };

    fetchData().catch(console.error);
  }, [address]);

  return (
    <div>
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <Col md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            Are you an artist and want to join the $INK ecosystem?{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="mailto: contact@flowtys.com"
              role="button"
              tabIndex="0"
              className="alert-link-flowty"
            >
              Contact us!
            </a>
          </div>
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center list-flowtys">
        <Col md="1">
          <img
            alt=""
            style={{ transition: "none" }}
            src={newIcon}
            className="new-icon "
          />
        </Col>
        {isMobile && (
          <Col xs="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={unchained}
              className="flowtys-art"
            />
          </Col>
        )}
        {!isMobile && (
          <Col xl="4" lg="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={unchained}
              className="flowtys-art"
            />
          </Col>
        )}

        <Col xl="3" lg="auto" className="d-flex flowtys-art-box">
          <div className="align-items-center justify-content-center">
            {/* <Row className="align-items-center mb-2">
              <img
                alt=""
                style={{ transition: "none" }}
                src={morphysLogo}
                className="morphys-logo"
              />
            </Row> */}
            <Row className="align-items-center justify-content-center mb-2">
              <span className="flowtys-art-title">MICKEY’S F®EE</span>
            </Row>
            <Row className="align-items-center justify-content-center mb-4">
              <span className="flowtys-art-text">
                <p>
                  by <ins>Flowtys</ins>
                </p>
              </span>
            </Row>
            <Row className="align-items-center justify-content-center mb-4">
              <span className="flowtys-art-text">
                This is a special year to us. Mickey is now entering the public
                domain after 96 years of imprisonment in the Disney Corp. We
                want to honor this occasion with a special aging artwork drop to
                you!
              </span>
            </Row>
            <Row className="align-items-center justify-content-center mb-4">
              <span className="flowtys-art-text">AGING IN STYLE</span>
              <span className="flowtys-art-text">
                Only the very first stylings of Mickey are entering the public
                domain this year. But do not worry! Our artwork will age, taking
                on the other styles of Mickey as they enter into public domain
                in the next 96 years to come.
              </span>
            </Row>
            <Row className="align-items-center justify-content-center">
              {allowance > 0 && (
                <span className="flowtys-art-price">
                  You can mint for 5000 $INK each
                </span>
              )}
            </Row>
            <Row className="align-items-center justify-content-center mb-3">
              {/* <span className="flowtys-art-price-sub">
                (max 50 per single TX)
              </span> */}
              <div className="align-bottom">
                {" "}
                <span className="flowtys-art-price-sub">Smart Contract: </span>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://etherscan.io/address/0x0103Ef28e2ba166B13479BCE997832687D64d047"
                  role="button"
                  tabIndex="0"
                  className="alert-link-flowty"
                >
                  {" "}
                  0x0103Ef28e2ba166B13479BCE997832687D64d047{" "}
                  <img
                    alt=""
                    style={{ transition: "none" }}
                    src={openLink}
                    className="external-link"
                  />
                </a>
              </div>
            </Row>
            <Row className="justify-content-center">
              <Col hidden={allowance === 0}>
                <Row className="w-100">
                  <InputGroup
                    className="mb-3 sale-input-box align-items-center"
                    size="lg"
                  >
                    <Button
                      className="sale-add sale-add-margin"
                      onClick={(e) => {
                        e.preventDefault();
                        setAmount(Math.max(1, amount - 1));
                      }}
                    >
                      -
                    </Button>

                    <Form.Control
                      disabled
                      className="sale-input"
                      type="number"
                      value={amount}
                      onChange={onInputchange}
                      isInvalid={amount > 50 || amount < 1}
                    />
                    {/*   <input type="text" formControlName="xyz" [readonly]="anyBooleanPropertyFromComponent" /> */}
                    <Button
                      className="sale-add"
                      onClick={(e) => {
                        e.preventDefault();
                        setAmount(Math.min(amount + 1, 50));
                      }}
                    >
                      +
                    </Button>
                    {/* <Button
                      className="sale-max"
                      onClick={(e) => {
                        e.preventDefault();
                        setAmount(amountPossible > 50 ? 50 : amountPossible);
                      }}
                    >
                      MAX
                    </Button> */}
                    <Button
                      className="sale-mint-button"
                      disabled={waitMint || inkBalance < amount * 5000}
                      onClick={(e) => {
                        e.preventDefault();
                        mintArt();
                      }}
                    >
                      <Spinner
                        hidden={!waitMint}
                        as="span"
                        animation="border"
                        size="sm"
                        role="status"
                        aria-hidden="true"
                      />
                      {inkBalance < amount * 500 ? "low $INK" : "MINT"}
                    </Button>
                    <Form.Control.Feedback type="invalid">
                      Maximum {amountPossible} NFT per wallet.
                    </Form.Control.Feedback>
                  </InputGroup>
                </Row>
              </Col>
              <Col hidden={allowance > 0}>
                {" "}
                <Button
                  disabled={waitApprove}
                  className="main-action-button mb-4"
                  onClick={setAllowanceInkArt}
                >
                  <Spinner
                    hidden={!waitApprove}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Allow $INK
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      {/* <Row className="mt-5 justify-content-center list-flowtys">
        <Col md="1">
          <img
            alt=""
            style={{ transition: "none" }}
            src={newIcon}
            className="new-icon new-icon-v"
          />
        </Col>
        {isMobile && (
          <Col xs="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={FlowtyArt}
              className="flowtys-art"
            />
          </Col>
        )}
        {!isMobile && (
          <Col xl="3" lg="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={FlowtyArt}
              className="flowtys-art"
            />
          </Col>
        )}
        <Col xl="3" lg="auto" className="d-flex flowtys-art-box">
          <div className="align-items-center justify-content-center">
            {" "}
            <Row className="align-items-center justify-content-center mb-2">
              <span className="flowtys-art-title">Flowtsome Lifetime</span>
            </Row>
            <Row className="align-items-center justify-content-center mb-4">
              <span className="flowtys-art-text">
                <p>
                  by <ins>Flowtys</ins>
                </p>
              </span>
            </Row>
            <Row className="align-items-center justify-content-center mb-4">
              <span className="flowtys-art-text">
                Flowtys’ one year anniversary artwork. It depicts a lumpy Flowty
                throughout its life stages as a baby, a teenager, adult and
                elderly FLowty. It takes approximately one year in Ethereum
                Blocks to go through the entire circle of this artwork. Once its
                life has ended, it’s reborn.
              </span>
            </Row>
            <Row className="align-items-center justify-content-center mb-3">
              <span className="flowtys-art-price">
                1/{artSupply} for <b>300 $INK</b>
              </span>
            </Row>
            <Row className="justify-content-center">
              <Col hidden={allowance === 0}>
                {" "}
                <Button
                  disabled={waitMint || inkBalance < 300 || isMinted}
                  className="main-action-button mb-4"
                  onClick={mintArt}
                >
                  <Spinner
                    hidden={!waitMint}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  {inkBalance < 300
                    ? "Not enough $INK"
                    : isMinted
                    ? "Minted!🎉"
                    : "Mint"}
                </Button>{" "}
              </Col>
              <Col hidden={allowance > 0}>
                {" "}
                <Button
                  disabled={waitApprove}
                  className="main-action-button mb-4"
                  onClick={setAllowanceInkArt}
                >
                  <Spinner
                    hidden={!waitApprove}
                    as="span"
                    animation="border"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  Allow $INK
                </Button>{" "}
              </Col>
            </Row>
          </div>
        </Col>
      </Row> */}
    </div>
  );
}

export default Shop;
