import { useState } from "react";
import BannerModal from "../components/BannerModal";
import PhoneModal from "../components/PhoneModal";
import { Row, Col, Button } from "react-bootstrap";
import info from "../assets/info.svg";
import FlowtysList from "../components/FlowtysList";
import MorphysList from "../components/MorphysList";

function BannerPage({ flowtys, morphys, mla }) {
  const [selectedFlowty, setSelectedFlowty] = useState([]);
  const [modalBannerShow, setModalBannerShow] = useState(false);
  const [modalPhoneShow, setModalPhoneShow] = useState(false);

  const generateBanner = () => {
    setModalBannerShow(true);
  };

  const generatePhoneBanner = () => {
    setModalPhoneShow(true);
  };

  return (
    <div>
      <BannerModal
        show={modalBannerShow}
        bannerArray={selectedFlowty}
        setShow={setModalBannerShow}
        onHide={() => setModalBannerShow(false)}
      />
      <PhoneModal
        show={modalPhoneShow}
        bannerArray={selectedFlowty}
        setShow={setModalPhoneShow}
        onHide={() => setModalPhoneShow(false)}
      />
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <Col md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            Choose up to 4 Flowtys („Twitter“) or up to 8 („Phone“)
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-1">
        <Col md="auto">
          <Button
            disabled={!selectedFlowty.length || selectedFlowty.length > 4}
            className="main-action-button "
            onClick={generateBanner}
          >
            Twitter
          </Button>{" "}
          <Button
            disabled={!selectedFlowty.length}
            className="main-action-button "
            onClick={generatePhoneBanner}
          >
            Phone
          </Button>{" "}
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center list-flowtys">
        <FlowtysList
          limit={9}
          flowtys={flowtys}
          morphys={morphys}
          mla={[]}
          filter={(el) =>
            el.name.includes("🎃") || el.name.includes("🎅")
              ? el.image
                  .replace(".gif", ".png")
                  .replace("metadata.flowtys.com", "studio.flowtys.com")
                  .replace(
                    "/images/",
                    el.name.includes("🎃")
                      ? "/halloween_gen_sd/images/"
                      : "/christmas_gen_sd/images/"
                  )
              : el.image
                  .replace(".gif", "_0.png")
                  .replace("metadata.flowtys.com", "studio.flowtys.com")
          }
          didSelect={(selected) => setSelectedFlowty(selected)}
        />
      </Row>
      {/* <Row className="mt-5 justify-content-center list-flowtys">
          <MorphysList 
              limit={9} 
              flowtys={morphys} 
              filter={(el) => 
                el.image
                .replace(".gif", ".png")
                .replace("metadata.flowtys.com", "studio.flowtys.com")
                .replace("/images/", "/halloween_gen_sd/images/")}
              didSelect={(selected) => setSelectedFlowty(selected) }
            />
        </Row> */}
    </div>
  );
}

export default BannerPage;
