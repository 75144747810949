import React from "react";
import crossBig from "../assets/cross-big.svg";
import { useRef } from "react";
import { Row, Button, Modal } from "react-bootstrap";
import { saveAs } from "file-saver";
import URLImage from "./URLImage";
import { Stage, Layer } from "react-konva";

function PhoneModal(props) {
  const stageRef = useRef(null);

  const downloadBanner = () => {
    const uri = stageRef.current.toDataURL({ pixelRatio: 2.5 });
    saveAs(uri, "flowtys_phone.jpg");
  };

  return (
    <Modal
      {...props}
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Button
            className="main-action-button download-button modal-title-button"
            onClick={downloadBanner}
          >
            {"Download"}
          </Button>{" "}
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="justify-content-center align-items-center">
          <div className="flowty-phone-box">
            <Row>
              <Stage
                width={360}
                height={640}
                ref={stageRef}
                className="pfp-banner-canvas"
              >
                <Layer width={360} height={640}>
                  <URLImage
                    src="/banner_bg.png"
                    x={0}
                    y={0}
                    width={360}
                    height={640}
                  />
                  {props.bannerArray.map((el, index) => {
                    if (props.bannerArray.length === 8) {
                      const xy = [
                        { x: 80, y: -110 },
                        { x: -80, y: -20 },
                        { x: 80, y: 50 },
                        { x: -100, y: 155 },
                        { x: 70, y: 210 },
                        { x: -100, y: 310 },
                        { x: 100, y: 350 },
                        { x: -10, y: 450 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={350}
                          height={350}
                        />
                      );
                    }
                    if (props.bannerArray.length === 7) {
                      const xy = [
                        { x: -80, y: -110 },
                        { x: 80, y: -20 },
                        { x: -100, y: 75 },
                        { x: 70, y: 170 },
                        { x: -100, y: 260 },
                        { x: 85, y: 350 },
                        { x: -90, y: 420 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={370}
                          height={370}
                        />
                      );
                    }
                    if (props.bannerArray.length === 6) {
                      const xy = [
                        { x: -80, y: -110 },
                        { x: 80, y: -20 },
                        { x: -100, y: 75 },
                        { x: 70, y: 170 },
                        { x: -100, y: 260 },
                        { x: 70, y: 350 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={390}
                          height={390}
                        />
                      );
                    }
                    if (props.bannerArray.length === 5) {
                      const xy = [
                        { x: 20, y: -120 },
                        { x: -140, y: -5 },
                        { x: 60, y: 90 },
                        { x: -140, y: 210 },
                        { x: 40, y: 330 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={480}
                          height={480}
                        />
                      );
                    }
                    if (props.bannerArray.length === 4) {
                      const xy = [
                        { x: -140, y: -140 },
                        { x: 20, y: 30 },
                        { x: -190, y: 120 },
                        { x: -30, y: 300 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={550}
                          height={550}
                        />
                      );
                    }
                    if (props.bannerArray.length === 3) {
                      const xy = [
                        { x: -90, y: -190 },
                        { x: -210, y: 40 },
                        { x: -120, y: 250 },
                      ];
                      return (
                        <URLImage
                          src={el}
                          x={xy[index].x}
                          y={xy[index].y}
                          width={700}
                          height={700}
                        />
                      );
                    }
                    if (props.bannerArray.length === 2) {
                      return (
                        <URLImage
                          src={el}
                          x={-200}
                          y={-160 + 350 * index}
                          width={800}
                          height={800}
                        />
                      );
                    }
                    return (
                      <URLImage
                        src={el.replace("_0.png", "_hd.jpg")}
                        x={-333}
                        y={-170}
                        width={1090}
                        height={1090}
                      />
                    );
                  })}
                </Layer>
              </Stage>
            </Row>
          </div>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PhoneModal;
