import { useState, useCallback, useRef } from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import FlowtysListStaked from "../components/FlowtysListStaked";
import FlowtysList from "../components/FlowtysListStake";
import {
  stakeMorphys,
  unstakeMorphys,
  approveMorphys,
} from "../model/Blockchain";

function MorphysListStaking({
  web3,
  address,
  morphys,
  morphysStaked,
  isApproved,
  didStake,
  didUnstake,
}) {
  const flowtysListRef = useRef();
  const [gotApprove, setGotApprove] = useState(false);
  const [waitApprove, setWaitApprove] = useState(false);
  const [selectedToStake, setSelectedToStake] = useState([]);
  const [selectedToUnStake, setSelectedToUnStake] = useState([]);
  const [waitStake, setWaitStake] = useState(false);
  const [waitUnstake, setWaitUnstake] = useState(false);

  const callbackFunctionStaked = useCallback(
    (selected) => {
      if (didStake) didStake(selected);
    },
    [didStake]
  );

  const callbackFunctionUnStaked = useCallback(
    (selected) => {
      if (didUnstake) didUnstake(selected);
    },
    [didUnstake]
  );

  const stake = (list) => {
    setWaitStake(true);
    stakeMorphys(
      web3,
      list.map((el) => el.id),
      address,
      () => {
        setSelectedToStake([]);
        callbackFunctionStaked(list);
        setWaitStake(false);
      },
      () => {
        setWaitStake(false);
      }
    );
  };

  const unstake = (list) => {
    setWaitUnstake(true);
    unstakeMorphys(
      web3,
      list.map((el) => el.id),
      address,
      () => {
        setSelectedToUnStake([]);
        callbackFunctionUnStaked(list);
        setWaitUnstake(false);
      },
      () => {
        setWaitUnstake(false);
      }
    );
  };

  const approve = () => {
    setWaitApprove(true);
    approveMorphys(web3, address, () => {
      setWaitApprove(false);
      setGotApprove(true);
    });
  };

  return (
    <>
      <div hidden={isApproved ? isApproved : gotApprove ? gotApprove : false}>
        <Row className="align-items-center justify-content-center">
          <Col md="auto">
            <Button
              disabled={waitApprove}
              className="main-action-button mb-4"
              onClick={() => {
                approve();
              }}
            >
              <Spinner
                hidden={!waitApprove}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Approve for Staking
            </Button>{" "}
          </Col>
        </Row>
      </div>
      <div hidden={gotApprove ? false : !isApproved}>
        <Row
          className="align-items-center justify-content-center mb-5"
          hidden={morphysStaked.length === 0}
        >
          <Col md="auto">
            <Button
              disabled={waitUnstake || selectedToUnStake.length === 0}
              className="main-action-button "
              onClick={() => {
                unstake(selectedToUnStake);
              }}
            >
              <Spinner
                hidden={!waitUnstake}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              UnStake Selected
            </Button>{" "}
            <Button
              disabled={waitUnstake || morphysStaked.length === 0}
              className="main-action-button "
              onClick={() => {
                unstake(morphysStaked);
              }}
            >
              <Spinner
                hidden={!waitUnstake}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              UnStake All
            </Button>{" "}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <FlowtysListStaked
            ref={flowtysListRef}
            flowtys={[]}
            morphys={morphysStaked}
            mla={[]}
            limit={50}
            didSelect={(selected) => {
              setSelectedToUnStake(selected);
            }}
            className="mt-3"
          />
        </Row>
        <Row
          className="align-items-center justify-content-center mb-5"
          hidden={morphys.length === 0}
        >
          <Col md="auto">
            <Button
              disabled={waitStake || selectedToStake.length === 0}
              className="main-action-button "
              onClick={() => {
                stake(selectedToStake);
              }}
            >
              <Spinner
                hidden={!waitStake}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Stake Selected
            </Button>{" "}
            <Button
              disabled={waitStake || morphys.length === 0}
              className="main-action-button "
              onClick={() => {
                stake(morphys);
              }}
            >
              <Spinner
                hidden={!waitStake}
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
              Stake All
            </Button>{" "}
          </Col>
        </Row>
        <Row className="justify-content-center">
          <FlowtysList
            ref={flowtysListRef}
            flowtys={[]}
            morphys={morphys}
            mla={[]}
            limit={50}
            didSelect={(selected) => {
              setSelectedToStake(selected);
            }}
            className="mt-3"
          />
        </Row>
      </div>
    </>
  );
}

export default MorphysListStaking;
