/* global BigInt */
import Web3 from "web3";
import * as Sentry from "@sentry/react";
const contractABI = require("../contracts/Flowtys.json");
const contractABIMorphys = require("../contracts/Morphys.json");
const contractABIMLA = require("../contracts/MoonlightLoveAffair.json");
const contractABIMORPHYSStake = require("../contracts/StakeMorphys.json");
const contractABIFLOWTYS = require("../contracts/StakeFlowtys.json");
const contractABIMLAStake = require("../contracts/StakeMLA.json");
const contractABIINK = require("../contracts/INK.json");
const contractABIINKProxy = require("../contracts/RewardsProxy.json");
const contractABIINKART = require("../contracts/FlowtyInkArt.json");
const contractABIMorpnhV2 = require("../contracts/MorphysV2.json");
const contractABIUNchained = require("../contracts/FlowtyUnchained.json");

const contractAddress = process.env.REACT_APP_FLOWTYS;
const morphysAddres = process.env.REACT_APP_MORPHYS;
const mlaAddres = process.env.REACT_APP_MLA;

const flowtysStakingAddres = process.env.REACT_APP_FLOWTYS_STAKE;
const morphysStakingAddres = process.env.REACT_APP_MORPHYS_STAKE;
const mlaStakingAddres = process.env.REACT_APP_MLA_STAKE;
const inkAddres = process.env.REACT_APP_INK;
const inkProxyAddress = process.env.REACT_APP_INK_PROXY;
const inkArtAddress = process.env.REACT_APP_INKART;
const morphysV2Address = process.env.REACT_APP_MV2;
const unchainedAddress = process.env.REACT_APP_UNCHAINED;

export async function getInkArtSupply(tokenId) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINKART.abi,
      inkArtAddress
    );
    const result = await contract.methods.getSupply(tokenId).call();
    return ParseFloat(result);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getMorphysOwned(owner) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIMorpnhV2.abi,
      morphysV2Address
    );
    const result = await contract.methods.balanceOf(owner).call();
    return ParseFloat(result);
  } catch (e) {
    console.log(e);
    return 0;
  }
}


export async function getAllowanceInkMorphys(owner) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods
      .allowance(owner, morphysV2Address)
      .call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getAllowanceInk(owner) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods
      .allowance(owner, inkArtAddress)
      .call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getAllowanceInkMickey(owner) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods
      .allowance(owner, unchainedAddress)
      .call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function setAllowanceInkMorphys(web3, owner, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(contractABIINK.abi, inkAddres);
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: owner,
      // target address, this could be a smart contract address
      to: inkAddres,
      // this encodes the ABI of the method and the arguments
      data: contract.methods
        .increaseAllowance(
          morphysV2Address,
          `${BigInt(amount * parseFloat(10 ** 18))}`
        )
        .encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function setAllowanceInk(web3, owner, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(contractABIINK.abi, inkAddres);
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: owner,
      // target address, this could be a smart contract address
      to: inkAddres,
      // this encodes the ABI of the method and the arguments
      data: contract.methods
        .increaseAllowance(
          inkArtAddress,
          `${BigInt(amount * parseFloat(10 ** 18))}`
        )
        .encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function setAllowanceInkMickey(web3, owner, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(contractABIINK.abi, inkAddres);
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: owner,
      // target address, this could be a smart contract address
      to: inkAddres,
      // this encodes the ABI of the method and the arguments
      data: contract.methods
        .increaseAllowance(
          unchainedAddress,
          `${BigInt(amount * parseFloat(10 ** 18))}`
        )
        .encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function mintMorphV2(web3, address, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(
      contractABIMorpnhV2.abi,
      morphysV2Address
    );
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: address,
      // target address, this could be a smart contract address
      to: morphysV2Address,
      // this encodes the ABI of the method and the arguments
      data: contract.methods.mintMorphy(amount).encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on('transactionHash', onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function mintMickeyArt(web3, address, tokenId, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(
      contractABIUNchained.abi,
      unchainedAddress
    );
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: address,
      // target address, this could be a smart contract address
      to: unchainedAddress,
      // this encodes the ABI of the method and the arguments
      data: contract.methods.mintArt(amount).encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function mintInkArt(web3, address, tokenId, amount, onDone) {
  try {
    const contract = new web3.eth.Contract(
      contractABIINKART.abi,
      inkArtAddress
    );
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: address,
      // target address, this could be a smart contract address
      to: inkArtAddress,
      // this encodes the ABI of the method and the arguments
      data: contract.methods.mintArt(tokenId, amount).encodeABI(),
    };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
      });
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export function ParseFloat(str, val) {
  str = str.toString();
  if (str.indexOf(".") === -1) {
    return Number(str);
  }
  str = str.slice(0, str.indexOf(".") + val + 1);
  return Number(str);
}

export async function getINKBalance(address) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINK.abi,
      inkAddres
    );
    const result = await contract.methods.balanceOf(address).call();
    return ParseFloat(parseFloat(result) / parseFloat(10 ** 18), 2);
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function getINKRate(flowtys, morphys, mla) {
  let flowtysRates = [5, 10, 15, 20],
    morphysRate = 2,
    mlaRate = 2;
  const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
  const endPromo = 15326799;
  const currentBlock = await web3Provider.eth.getBlockNumber();
  let sum = flowtys
    .map((el) => flowtysRates[el.age])
    .map((el) => el + (currentBlock < endPromo ? 15 : 0))
    .reduce((a, b) => a + b, 0);
  console.log(sum);
  sum += morphys.map((el) => morphysRate).reduce((a, b) => a + b, 0);
  sum += mla.map((el) => mlaRate).reduce((a, b) => a + b, 0);
  return sum;
}

export async function claimRewards(web3, address, onDone, onErr) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIINKProxy.abi,
      inkProxyAddress
    );
    const tx = {
      maxPriorityFeePerGas: null,
      maxFeePerGas: null,
      // this could be provider.addresses[0] if it exists
      from: address,
      // target address, this could be a smart contract address
      to: inkProxyAddress,
      // this encodes the ABI of the method and the arguments
      data: contract.methods.claimAll().encodeABI(),
    };
    // const contract = new web3Provider.eth.Contract(
    //   contractABIFLOWTYS.abi,
    //   flowtysStakingAddres
    // );
    // const tx = {
    //   maxPriorityFeePerGas: null,
    //   maxFeePerGas: null,
    //   // this could be provider.addresses[0] if it exists
    //   from: address,
    //   // target address, this could be a smart contract address
    //   to: flowtysStakingAddres,
    //   // this encodes the ABI of the method and the arguments
    //   data: contract.methods.claimRewardsAll().encodeABI()
    // };
    web3.eth
      .sendTransaction(tx)
      .on("receipt", onDone)
      .on("error", function (error) {
        console.log(error);
        if (onErr) {
          onErr(error);
        }
      });
  } catch (e) {
    if (onErr) {
      onErr(e);
    }
    return 0;
  }
}

export async function rewardsFlowtys(address, tokenIds) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIFLOWTYS.abi,
      flowtysStakingAddres
    );
    const result = await contract.methods
      .calculateRewards(address, tokenIds)
      .call();
    return ParseFloat(
      result
        .map(parseFloat)
        .map((a) => a / 10 ** 18)
        .reduce((a, b) => a + b, 0),
      2
    );
  } catch {
    return 0;
  }
}

export async function balanceFlowtysStakedAll() {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABI.abi,
      contractAddress
    );
    const balance = await contract.methods
      .balanceOf(flowtysStakingAddres)
      .call();
    return balance;
  } catch {
    return 0;
  }
}

export async function rewardsMorphys(address, tokenIds) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIMORPHYSStake.abi,
      morphysStakingAddres
    );
    const result = await contract.methods
      .calculateRewards(address, tokenIds)
      .call();
    return ParseFloat(
      result
        .map(parseFloat)
        .map((a) => a / 10 ** 18)
        .reduce((a, b) => a + b, 0),
      2
    );
  } catch {
    return 0;
  }
}

export async function balanceMorphysStakedAll() {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABI.abi,
      contractAddress
    );
    const balance = await contract.methods
      .balanceOf(morphysStakingAddres)
      .call();
    return balance;
  } catch {
    return 0;
  }
}

export async function rewardsMLA(address, tokenIds) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIMLAStake.abi,
      mlaStakingAddres
    );
    const result = await contract.methods
      .calculateRewards(address, tokenIds)
      .call();
    return ParseFloat(
      result
        .map(parseFloat)
        .map((a) => a / 10 ** 18)
        .reduce((a, b) => a + b, 0),
      2
    );
  } catch (e) {
    console.log(e);
    return 0;
  }
}

export async function balanceMLAStakedAll() {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_ALCH_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABI.abi,
      contractAddress
    );
    const balance = await contract.methods.balanceOf(mlaStakingAddres).call();
    return balance;
  } catch {
    return 0;
  }
}

export async function isApproveFlowtys(address) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABI.abi,
      contractAddress
    );
    const result = await contract.methods
      .isApprovedForAll(address, flowtysStakingAddres)
      .call();
    return result;
  } catch {
    return false;
  }
}

export async function approveFlowtys(web3, address, onDone) {
  const contract = new web3.eth.Contract(contractABI.abi, contractAddress);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: address,
    // target address, this could be a smart contract address
    to: contractAddress,
    // this encodes the ABI of the method and the arguements
    data: contract.methods
      .setApprovalForAll(flowtysStakingAddres, true)
      .encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onDone)
    .on("error", function (error) {
      console.log(error);
    });
}

export async function isApproveMorphys(address) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIMorphys.abi,
      morphysAddres
    );

    const result = await contract.methods
      .isApprovedForAll(address, morphysStakingAddres)
      .call();
    return result;
  } catch {
    return false;
  }
}

export async function approveMorphys(web3, address, onHash) {
  const contract = new web3.eth.Contract(contractABIMorphys.abi, morphysAddres);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: address,
    // target address, this could be a smart contract address
    to: morphysAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods
      .setApprovalForAll(morphysStakingAddres, true)
      .encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
    });
}

export async function isApproveMLA(address) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    const contract = new web3Provider.eth.Contract(
      contractABIMLA.abi,
      mlaAddres
    );

    const result = await contract.methods
      .isApprovedForAll(address, mlaStakingAddres)
      .call();
    return result;
  } catch {
    return false;
  }
}

export async function approveMLA(web3, address, onHash) {
  const contract = new web3.eth.Contract(contractABIMLA.abi, mlaAddres);
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: address,
    // target address, this could be a smart contract address
    to: mlaAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods
      .setApprovalForAll(mlaStakingAddres, true)
      .encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
    });
}

export async function getTokensData(address, isStaked) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    if (isStaked) {
      const contractStaking = new web3Provider.eth.Contract(
        contractABIFLOWTYS.abi,
        flowtysStakingAddres
      );
      const contract = new web3Provider.eth.Contract(
        contractABI.abi,
        contractAddress
      );
      const tokensIds = await contractStaking.methods
        .depositsOf(address)
        .call();
      if (!tokensIds || !tokensIds.length) {
        return [];
      }
      const currentBlock = await web3Provider.eth.getBlockNumber();
      const tokensData = await Promise.all(
        tokensIds.map(async (tokenId) => {
          const startingBlock = await contract.methods
            .getAgeStaringBlock(tokenId)
            .call();
          const ageInDays = getAgeInDays(startingBlock, currentBlock);
          const ageLevelPercentage = getAgeLevelPercentage(
            startingBlock,
            currentBlock
          );
          return {
            tokenId,
            age: Math.min(
              Math.round((currentBlock - startingBlock) / 518400),
              3
            ),
            ageInDays,
            ageLevelPercentage,
          };
        })
      );
      return tokensData;
    } else {
      const contract = new web3Provider.eth.Contract(
        contractABI.abi,
        contractAddress
      );
      const balance = await contract.methods.balanceOf(address).call();
      console.log(balance);

      if (balance === 0) {
        return [];
      }
      const currentBlock = await web3Provider.eth.getBlockNumber();
      const tokensData = await Promise.all(
        Array.from({ length: balance }, (_, i) => i).map(async (index) => {
          const tokenId = await contract.methods
            .tokenOfOwnerByIndex(address, index)
            .call();
          const startingBlock = await contract.methods
            .getAgeStaringBlock(tokenId)
            .call();
          const ageInDays = getAgeInDays(startingBlock, currentBlock);
          const ageLevelPercentage = getAgeLevelPercentage(
            startingBlock,
            currentBlock
          );
          return {
            tokenId,
            age: Math.min(
              Math.round((currentBlock - startingBlock) / 518400),
              3
            ),
            ageInDays,
            ageLevelPercentage,
          };
        })
      );
      return tokensData;
    }
  } catch (err) {
    Sentry.captureException(err);
  }
  return [];
}

export async function stakeFlowtys(web3, tokenIds, accountFrom, onHash, onErr) {
  const contract = new web3.eth.Contract(
    contractABIFLOWTYS.abi,
    flowtysStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: flowtysStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.deposit(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function unstakeFlowtys(
  web3,
  tokenIds,
  accountFrom,
  onHash,
  onErr
) {
  const contract = new web3.eth.Contract(
    contractABIFLOWTYS.abi,
    flowtysStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: flowtysStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.withdraw(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function stakeMorphys(web3, tokenIds, accountFrom, onHash, onErr) {
  const contract = new web3.eth.Contract(
    contractABIMORPHYSStake.abi,
    morphysStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: morphysStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.deposit(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function unstakeMorphys(
  web3,
  tokenIds,
  accountFrom,
  onHash,
  onErr
) {
  const contract = new web3.eth.Contract(
    contractABIMORPHYSStake.abi,
    morphysStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: morphysStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.withdraw(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function stakeMLA(web3, tokenIds, accountFrom, onHash, onErr) {
  const contract = new web3.eth.Contract(
    contractABIMLAStake.abi,
    mlaStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: mlaStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.deposit(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function unstakeMLA(web3, tokenIds, accountFrom, onHash, onErr) {
  const contract = new web3.eth.Contract(
    contractABIMLAStake.abi,
    mlaStakingAddres
  );
  const tx = {
    maxPriorityFeePerGas: null,
    maxFeePerGas: null,
    // this could be provider.addresses[0] if it exists
    from: accountFrom,
    // target address, this could be a smart contract address
    to: mlaStakingAddres,
    // this encodes the ABI of the method and the arguements
    data: contract.methods.withdraw(tokenIds).encodeABI(),
  };
  web3.eth
    .sendTransaction(tx)
    .on("receipt", onHash)
    .on("error", function (error) {
      console.log(error);
      if (onErr) {
        onErr(error);
      }
    });
}

export async function getMorphysTokensData(address, isStaked) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    if (isStaked) {
      const contract = new web3Provider.eth.Contract(
        contractABIMORPHYSStake.abi,
        morphysStakingAddres
      );
      const contractRegular = new web3Provider.eth.Contract(
        contractABIMorphys.abi,
        morphysAddres
      );
      const tokensData = await contract.methods.depositsOf(address).call();
      if (!tokensData || !tokensData.length) {
        return [];
      }
      const allSeasons =
        tokensData && tokensData.length > 0
          ? await Promise.all(
            tokensData
              .map((el) => contractRegular.methods.tokenURI(el).call())
              .map((p) => p.catch((e) => ""))
          )
          : [];
      return tokensData.map((el, index) => {
        return {
          id: el,
          isHalloween: allSeasons[index].includes(
            "QmZ4SCSA3jrGo4Mu1DGokMECUBF2vMCD1EzCfpAiu7wuo6"
          ),
        };
      });
    } else {
      const contract = new web3Provider.eth.Contract(
        contractABIMorphys.abi,
        morphysAddres
      );
      const balance = await contract.methods.balanceOf(address).call();
      if (balance === 0) {
        return [];
      }
      const tokensData = await Promise.all(
        Array.from({ length: balance }, (_, i) => i).map(async (index) => {
          const tokenId = await contract.methods
            .tokenOfOwnerByIndex(address, index)
            .call();
          return tokenId;
        })
      );
      const allSeasons =
        tokensData && tokensData.length > 0
          ? await Promise.all(
            tokensData
              .map((el) => contract.methods.tokenURI(el).call())
              .map((p) => p.catch((e) => ""))
          )
          : [];
      return tokensData.map((el, index) => {
        return {
          id: el,
          isHalloween: allSeasons[index].includes(
            "QmZ4SCSA3jrGo4Mu1DGokMECUBF2vMCD1EzCfpAiu7wuo6"
          ),
        };
      });
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
  }
  return [];
}

export async function getMLATokenData(address, isStaked) {
  try {
    const web3Provider = new Web3(process.env.REACT_APP_WEB3_API);
    if (isStaked) {
      const contract = new web3Provider.eth.Contract(
        contractABIMLAStake.abi,
        mlaStakingAddres
      );
      const tokensData = await contract.methods.depositsOf(address).call();
      if (!tokensData || !tokensData.length) {
        return [];
      }
      return tokensData.map((el, index) => {
        return {
          id: el,
        };
      });
    } else {
      const contract = new web3Provider.eth.Contract(
        contractABIMLA.abi,
        mlaAddres
      );
      const balance = await contract.methods.balanceOf(address).call();
      if (balance === 0) {
        return [];
      }
      const tokensData = await Promise.all(
        Array.from({ length: balance }, (_, i) => i).map(async (index) => {
          const tokenId = await contract.methods
            .tokenOfOwnerByIndex(address, index)
            .call();
          return tokenId;
        })
      );
      return tokensData.map((el, index) => {
        return {
          id: el,
        };
      });
    }
  } catch (err) {
    console.log(err);
    Sentry.captureException(err);
  }
  return [];
}

/**
 * Returns the time difference between the current ethereum block and the token starting block in days (using a 15s average)
 * @param {uint} tokenStartingBlock The block number when the token was mint or transacted for the last time, this is obtained by executing getAgeStaringBlock
 * @param {*} currentBlock Current Ethereum block, this can be obtained directly from web3 library. i.e. web3Provider.eth.getBlockNumber()
 */
function getAgeInDays(tokenStartingBlock, currentBlock) {
  // Block time average in seconds
  // this is the value used by the ethereum Flowtys contract to calculate when to increase the age treat
  // See more here: https://etherscan.io/address/0x52607cb9c342821ea41ad265B9Bb6a23BEa49468#code
  const BLOCK_TIME_AVG = 15;
  if (!currentBlock || !tokenStartingBlock) {
    console.error("currentBlock or tokenStartingBlock was empty");
    return 0;
  }
  if (tokenStartingBlock >= currentBlock) {
    return 0;
  }
  return parseInt(
    ((currentBlock - tokenStartingBlock) * BLOCK_TIME_AVG) / (3600 * 24)
  );
}

function getAgeLevelPercentage(tokenStartingBlock, currentBlock) {
  if (!currentBlock || !tokenStartingBlock) {
    console.error("currentBlock or tokenStartingBlock was empty");
    return 0;
  }
  if (tokenStartingBlock >= currentBlock) {
    return 0;
  }
  const ageBlockThreshold = 518400; // Obtained from Flowtys contract: https://etherscan.io/address/0x52607cb9c342821ea41ad265B9Bb6a23BEa49468#code
  const level = (currentBlock - tokenStartingBlock) / ageBlockThreshold;
  if (level >= 3) {
    // Means that the flowty has fully adged
    return 100;
  }
  return (level % 1) * 100;
}
