import React from "react";
import { Row, Col } from "react-bootstrap";
import woodman from "../assets/tin-woodman.webp";
import "../styles/MobilePlaceholder.css";

function MobilePlaceholder() {
  return (
    <Row className="align-items-center justify-content-center main-box">
      <Col className="align-items-center justify-content-center">
        <Row md="auto" className="justify-content-center w-100">
          <div className="text-center  center-div">
            <div className="text-center">
              <Row className="justify-content-center">
                <img
                  alt=""
                  src="https://static.wixstatic.com/media/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.png/v1/fill/w_300,h_100,al_c,q_85,usm_0.66_1.00_0.01/f13776_d11ccab03ede4e638b2bbbaf6bf335ac~mv2.webp"
                  width="150"
                  height="50"
                  className="flowtys_logo"
                />{" "}
              </Row>
              <Row className="justify-content-center">
                <img alt="" src={woodman} className="flowtys_woodman" />{" "}
              </Row>
              <Row className="justify-content-center">
                <div className="div-text text-center align-items-center">
                  <div className="text-row">This page was designed to be</div>
                  <div className="text-row">experienced via a desktop</div>
                  <div className="text-row">computer or laptop.</div>
                </div>
              </Row>
            </div>
          </div>
        </Row>
      </Col>
    </Row>
  );
}

export default MobilePlaceholder;
