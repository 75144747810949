import { useState, useCallback, useRef } from "react";
import { Row, Col, Tab, Tabs, Spinner } from "react-bootstrap";
import info from "../assets/info.svg";
import balance from "../assets/stakingBalance.webp";
import action from "../assets/stakingAction.webp";
import FlowtysList from "../components/FlowtysListStaking";
import MorphysList from "../components/MorphysListStaking";
import MLAList from "../components/MLAListStaking";
import Progress from "../components/LinerProgressBar";
import { claimRewards, ParseFloat } from "../model/Blockchain";

function StakingList({
  web3,
  address,
  flowtys,
  morphys,
  mla,
  flowtysStaked,
  morphysStaked,
  mlaStaked,
  isApprovedFlowtys,
  isApprovedMorphys,
  isApprovedMLA,
  inkBalance,
  rewardsToClaim,
  flowtysStakedAll,
  inkDaily,
  inkDailyAll,
  didStake,
  didUnstake,
  onMessage,
}) {
  const [claiming, setClaiming] = useState(false);
  const [toClaim, setToClaim] = useState();
  const [claimedInk, setClaimedInk] = useState();

  const callbackFunctionStaked = useCallback(
    (_flowtys, _morhys, _mla) => {
      if (didStake) didStake(_flowtys, _morhys, _mla);
    },
    [didStake]
  );

  const callbackFunctionUnStaked = useCallback(
    (_flowtys, _morhys, _mla) => {
      if (didUnstake) didUnstake(_flowtys, _morhys, _mla);
    },
    [didUnstake]
  );

  const callbackFunctionMessage = useCallback(
    (head, body) => {
      if (onMessage) onMessage(head, body);
    },
    [onMessage]
  );

  const claim = () => {
    setClaiming(true);
    claimRewards(
      web3,
      address,
      () => {
        setToClaim(0);
        setClaimedInk(ParseFloat(inkBalance + rewardsToClaim, 2));
        setClaiming(false);
        callbackFunctionMessage(
          "$INK arrived",
          "Your $INK has been claimed 🤑"
        );
      },
      () => {
        setClaiming(false);
      }
    );
  };

  return (
    <div>
      <Row className="align-items-center justify-content-center mb-4 mt-5 ">
        <Col md="auto staking-panel">
          <Row className="align-items-center justify-content-center">
            <Col>
              <Row className="align-items-center justify-content-center mt-5">
                <Col md="auto">
                  <img
                    alt=""
                    style={{ transition: "none" }}
                    src={balance}
                    className="staking-img"
                  />
                </Col>
                <Col md="auto" className="ml-3">
                  <Row className="align-items-center justify-content-center">
                    <Spinner
                      hidden={!claiming}
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                    <span hidden={claiming} className="staking-lbl">
                      {claimedInk ? claimedInk : inkBalance}
                    </span>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <span className="staking-sub-lbl">My Balance</span>
                  </Row>
                </Col>
              </Row>
            </Col>
            <Col>
              <Row className="align-items-center justify-content-center mt-5">
                <Col md="auto">
                  <img
                    alt=""
                    style={{ transition: "none" }}
                    src={action}
                    className="staking-img staking-img-click"
                    onClick={claim}
                    disabled={claiming}
                  />
                </Col>
                <Col md="auto" className="ml-3">
                  <Row className="align-items-center justify-content-center">
                    <Spinner
                      hidden={!claiming}
                      as="span"
                      animation="border"
                      size="md"
                      role="status"
                      aria-hidden="true"
                    />
                    <span hidden={claiming} className="staking-lbl">
                      {toClaim === 0 ? toClaim : rewardsToClaim}
                    </span>
                  </Row>
                  <Row className="align-items-center justify-content-center">
                    <span className="staking-sub-lbl">Available to claim</span>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="mt-40 staking-info-row">
            <Col className="col-auto mr-auto">
              <span className="staking-lbl-cnt">{inkDaily} $INK</span>
            </Col>
            <Col className="col-auto mr-3 ml-3">
              <span className="staking-sub-lbl ml-10-i">
                daily rewards with currently staked assets
              </span>
            </Col>
          </Row>
          <Row className="mt-1 staking-info-row">
            <Col className="col-auto mr-auto">
              <span className="staking-lbl-cnt">{inkDailyAll} $INK</span>
            </Col>
            <Col className="col-auto mr-3 ml-3">
              <span className="staking-sub-lbl ml-10-i">
                possible daily rewards if all your assets were staked
              </span>
            </Col>
          </Row>
          <Row className=" staking-info-row mt-40">
            <Col className="col-auto mr-auto">
              <span className="staking-lbl-cnt">
                {flowtysStaked.length} / {morphysStaked.length} /{" "}
                {mlaStaked.length}
              </span>
            </Col>
            <Col className="col-auto mr-3 ml-3">
              <span className="staking-sub-lbl ml-10-i">
                of your Flowtys / Morphys / MLA are staked
              </span>
            </Col>
          </Row>
          <Row className="staking-info-row mt-4">
            <Col className="">
              <Progress completed={(flowtysStakedAll / 10000) * 100}></Progress>
            </Col>
            <Col className="col-auto mr-3 ml-3">
              <span className="flowtys-progress-txt pr-3 mr-3 ml-3">
                {flowtysStakedAll} / 10k Flowtys staked
              </span>
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-4 mt-3">
        <Col md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            {" "}
            Learn more about staking and $INK{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://www.flowtys.com/ink"
              role="button"
              tabIndex="0"
              className="alert-link-flowty"
            >
              here
            </a>
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-4">
        <Col md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            It's safer to <u>delist</u> your NFTs before staking
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <div className="staking-div">
          <Tabs
            defaultActiveKey="flowtys"
            id="uncontrolled-tab-example"
            className=""
          >
            <Tab
              eventKey="flowtys"
              title={`Flowtys 🎩 (${flowtys.length})`}
              className="staking-tab"
            >
              <FlowtysList
                web3={web3}
                address={address}
                flowtys={flowtys}
                flowtysStaked={flowtysStaked}
                isApproved={isApprovedFlowtys}
                didStake={(list) => {
                  callbackFunctionStaked(list, null, null);
                }}
                didUnstake={(list) => {
                  callbackFunctionUnStaked(list, null, null);
                }}
                className="mt-3"
              />
            </Tab>
            <Tab
              eventKey="morphys"
              title={`Morphys 🎅 (${morphys.length})`}
              className="staking-tab"
            >
              <MorphysList
                web3={web3}
                address={address}
                morphys={morphys}
                morphysStaked={morphysStaked}
                isApproved={isApprovedMorphys}
                didStake={(list) => {
                  callbackFunctionStaked(null, list, null);
                }}
                didUnstake={(list) => {
                  callbackFunctionUnStaked(null, list, null);
                }}
                className="mt-3"
              />
            </Tab>
            <Tab
              eventKey="mla"
              title={`MLA 🕺 (${mla.length})`}
              className="staking-tab"
            >
              <MLAList
                web3={web3}
                address={address}
                mla={mla}
                mlaStaked={mlaStaked}
                isApproved={isApprovedMLA}
                didStake={(list) => {
                  callbackFunctionStaked(null, null, list);
                }}
                didUnstake={(list) => {
                  callbackFunctionUnStaked(null, null, list);
                }}
                className="mt-3"
              />
            </Tab>
          </Tabs>
        </div>
      </Row>
    </div>
  );
}

export default StakingList;
