import { useState, useEffect, useRef } from "react";
import PFPModal from "../components/PFPModal";
import PFPModalMorphy from "../components/PFPModalMorphy";
import PFPModalMLA from "../components/PFPModalMLA";
import { Row, Col } from "react-bootstrap";
import info from "../assets/info.svg";
import FlowtysList from "../components/FlowtysList";
import MorphysList from "../components/MorphysList";

function PFP({flowtys, morphys, mla}) {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowMorphy, setModalShowMorphy] = useState(false);
  const [modalShowMLA, setModalShowMLA] = useState(false);

	const flowtysListRef = useRef();
  const [selected, setSelected] = useState([]);
  return (
      <div>
        <PFPModal
          show={modalShow}
          
          selected={selected && selected.length ? selected[0] : {}}
          setShow={setModalShow}
          onHide={() => setModalShow(false)}
        />
        <PFPModalMorphy
          show={modalShowMorphy}
          selected={selected && selected.length ? selected[0] : {}}
          setShow={setModalShowMorphy}
          onHide={() => setModalShowMorphy(false)}
        />
        <PFPModalMLA
          show={modalShowMLA}
          selected={selected && selected.length ? selected[0] : {}}
          setShow={setModalShowMLA}
          onHide={() => setModalShowMLA(false)}
        />
        <Row className="align-items-center justify-content-center mb-4 mt-5">
          <Col md="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto">
            <div className="pfp-modal-title"> Click on a Flowty to download its JPG/PNG</div>
          </Col>
        </Row>
        <Row className="mt-5 justify-content-center list-flowtys">
          <FlowtysList ref={flowtysListRef} limit={1} flowtys={flowtys} morphys={morphys} mla={mla} didSelect={(selected) => { 
              setSelected(selected);
              if (selected.length) {
                console.log(selected[0])
                  if (selected[0].animation_url) {
                    setModalShowMLA(true)
                  } else {
                    (selected[0].name.includes('🎃') || selected[0].name.includes('🎅')) ? setModalShowMorphy(true) : setModalShow(true); 
                  }
              }  
            } } />
        </Row>
      </div>
  );
}

export default PFP;