import { Circle } from "rc-progress";

export const ProgressBar = (props) => {
  return (
    <div className="content-progress-bar">
      <Circle
        percent={props.percent}
        strokeWidth={3}
        strokeColor={props.strokeColor ? props.strokeColor : "#FFFFFF"} 
        strokeLinecap="round"
      />
    </div>
  );
};

export default ProgressBar;
