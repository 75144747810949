import React from "react";
import crossBig from "../assets/cross-big.svg";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
import { saveAs } from "file-saver";
import info from "../assets/info.svg";
import { useState } from "react";

function PFPModal(props) {
  const [currentFrame, setFrame] = useState("");

  const saveGifFile = async (event) => {
    const file = `https://studio.flowtys.com/mla_gif/${props.selected.id}.gif`
    event.preventDefault();
    saveAs(file, `${props.selected.name}.gif`);
  };

  const saveInstrument = async (name) => {
    const file = `https://studio.flowtys.com/mla_instruments/${name}.wav`
    saveAs(file, `${name}.wav`);
  };

  const saveHDPNGFile = async (event) => {
    const file = props.selected.image;
    event.preventDefault();
    saveAs(file, `${props.selected.name}.png`);
  };

  const saveAudioFile = async (event) => {
    const file = `https://studio.flowtys.com/mla_audio/${props.selected.id}.wav`
    event.preventDefault();
    saveAs(file, `${props.selected.name}.wav`);
  };

  const saveFile = async () => {
    const file = props.selected.animation_url;
    saveAs(file, `${props.selected.name}.mp4`);
  };
  return (
    <Modal
      {...props}
      size="lg"
      onShow={() => {
      }}
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-90h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto">
            <div className="pfp-modal-title">
              {" "}
              Download high quality or PNG frame
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center justify-content-center h-100">
          <Row className="align-items-center justify-content-center mb-3">
            <Col md="auto">
              <Button
                className="main-action-button download-button"
                onClick={saveFile}
              >
                Download
              </Button>{" "}
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <video
              className="ratio-1x1 pfp-img"
              src={props.selected.animation_url}
              alt="loading..."
              autoPlay={true}
              controls={false}
              loop={true}
              muted={false}
              poster={props.selected.image}
            />
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <Col md="auto">
              <img
                alt=""
                style={{ transition: "none" }}
                src={info}
                className="info-img"
              />
            </Col>
            <Col md="auto">
              <div className="pfp-modal-title">
                {" "}
                Get the media resources
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveGifFile}
              >
                GIF
              </Button>{" "}
            </Col>
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveHDPNGFile}
              >
                Poster
              </Button>{" "}
            </Col>
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveAudioFile}
              >
                Audio Track
              </Button>{" "}
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mt-2 mb-3">
            <Col md="auto">
              <img
                alt=""
                style={{ transition: "none" }}
                src={info}
                className="info-img"
              />
            </Col>
            <Col md="auto">
              <div className="pfp-modal-title">
                {" "}
                Get the audio instruments
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            {props.selected.attributes ? props.selected.attributes.filter(el => el.trait_type.startsWith('Audio') && el.value !== 'None').map((item, index) => {
              return (<Col md="auto mb-2">
                <Button
                  className="main-action-button modal-button-margin"
                  onClick={el => { 
                    el.preventDefault();
                    saveInstrument(item.value);
                  }}
                >
                  ⇣ {item.value}
                </Button>{" "}
              </Col>);
              }) : {}
            }
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PFPModal;
