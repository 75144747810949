import React from "react";
import flowty from "../assets/progress_flowtys.png";
import { Image } from "react-bootstrap";
const ProgressBar = (props) => {
  const { completed } = props;

  const containerStyles = {
    marginTop: 5,
    height: 15,
    width: "100%",
    backgroundColor: "transparent",
    borderRadius: 50,
    borderColor: "#000",
    borderWidth: "2px",
    borderStyle: "solid",
  };

  const fillerStyles = {
    height: "100%",
    width: `${completed}%`,
    backgroundColor: "#000",
    borderRadius: "inherit",
    textAlign: "right",
  };

  return (
    <div style={containerStyles}>
      <div style={fillerStyles}>
        <Image
          className="flowty-img-progress"
          src={flowty}
          alt="loading..."
          roundedCircle
          crossOrigin="anonymous"
        />
      </div>
    </div>
  );
};

export default ProgressBar;
