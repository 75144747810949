import { useState } from "react";
import PromoGifModal from "../components/PromoGifModal";
import { Row, Col, Button, InputGroup, Form } from "react-bootstrap";
import info from "../assets/info.svg";
import FlowtysList from "../components/FlowtysList";
import MorphysList from "../components/MorphysList";

function Promo({ flowtys }) {
  const [selectedFlowty, setSelectedFlowty] = useState([]);
  const [modalPromoShow, setModalPromoShow] = useState(false);
  const [twitterUsername, setUsername] = useState("");

  function onInputChange(event) {
    setUsername(event.target.value);
  }

  const generatePromo = () => {
    setModalPromoShow(true);
  };

  return (
    <div>
      <PromoGifModal
        show={modalPromoShow}
        folders={selectedFlowty}
        username={twitterUsername}
        setShow={setModalPromoShow}
        url="https://media.subs.tv/gif3.gif"
        onHide={() => setModalPromoShow(false)}
      />
      <Row className="align-items-center justify-content-center mb-4 mt-5">
        <Col md="auto">
          <img
            alt=""
            style={{ transition: "none" }}
            src={info}
            className="info-img"
          />
        </Col>
        <Col md="auto">
          <div className="pfp-modal-title">
            Choose up to 10 Flowtys, then „Generate“
          </div>
        </Col>
      </Row>
      <Row className="align-items-center justify-content-center mb-1">
        <Col md="auto" className="align-items-center justify-content-center">
          <InputGroup
            className="mb-3 flowty-input-box align-items-center justify-content-center"
            size="lg"
          >
            <Form.Control
              required
              className="flowty-input"
              placeholder="Add @TwitterUsername"
              onChange={onInputChange}
              value={twitterUsername}
            />
          </InputGroup>
        </Col>
        <Col md="auto">
          <Button
            disabled={!selectedFlowty.length}
            className="main-action-button "
            onClick={generatePromo}
          >
            Generate
          </Button>{" "}
        </Col>
      </Row>
      <Row className="mt-5 justify-content-center list-flowtys">
        <FlowtysList
          limit={11}
          flowtys={flowtys}
          morphys={[]}
          mla={[]}
          filter={(el) =>
            el.image
              .replace(".gif", "")
              .replace("https://metadata.flowtys.com/", "")
          }
          didSelect={(selected) => setSelectedFlowty(selected)}
        />
      </Row>
    </div>
  );
}

export default Promo;
