import React from "react";
import crossBig from "../assets/cross-big.svg";
import { Row, Col, Button, Modal, Image } from "react-bootstrap";
import { saveAs } from "file-saver";
import info from "../assets/info.svg";
import { useState } from "react";

function PFPModal(props) {
  const [currentFrame, setFrame] = useState("");

  const saveHDJPGFile = async (event) => {
    const file = props.selected.image
      .replace(".gif", ".jpg")
      .replace("metadata.flowtys.com", "studio.flowtys.com")
      .replace("/images/", "/halloween_gen_hd/images/");
    event.preventDefault();
    saveAs(file, `${props.selected.name}.jpg`);
  };

  const saveHDPNGFile = async (event) => {
    const file = props.selected.image
      .replace(".gif", ".png")
      .replace("metadata.flowtys.com", "studio.flowtys.com")
      .replace("/images/",  props.selected.name.includes('🎃') ? "/halloween_gen_sd/images/" : "/christmas_gen_sd/images/");
    event.preventDefault();
    saveAs(file, `${props.selected.name}.png`);
  };

  const saveFile = async () => {
    console.log(currentFrame);
    saveAs(currentFrame, `avatar_${props.selected.name}.jpg`);
  };
  return (
    <Modal
      {...props}
      size="lg"
      onShow={() => {
        setFrame(
          props.selected.image
            .replace(".gif", ".jpg")
            .replace("metadata.flowtys.com", "studio.flowtys.com")
            .replace("/images/", "/halloween_gen_sd/images/")
        );
      }}
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-90h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Col md="auto">
            <img
              alt=""
              style={{ transition: "none" }}
              src={info}
              className="info-img"
            />
          </Col>
          <Col md="auto">
            <div className="pfp-modal-title">
              {" "}
              Download high quality or PNG frame
            </div>
          </Col>
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center justify-content-center h-100">
          <Row className="align-items-center justify-content-center mb-3">
            <Col md="auto">
              <Button
                className="main-action-button download-button"
                onClick={saveFile}
              >
                Download
              </Button>{" "}
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <Image
              className="ratio-1x1 pfp-img"
              src={currentFrame}
              alt="loading..."
              roundedCircle
              onClick={(e) => {
                e.preventDefault();
                props.setShow(true);
              }}
            />
          </Row>
          <Row className="align-items-center justify-content-center mb-3">
            <Col md="auto">
              <img
                alt=""
                style={{ transition: "none" }}
                src={info}
                className="info-img"
              />
            </Col>
            <Col md="auto">
              <div className="pfp-modal-title">
                {" "}
                Get the frame in high resolution
              </div>
            </Col>
          </Row>
          <Row className="align-items-center justify-content-center">
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveHDJPGFile}
              >
                JPG
              </Button>{" "}
            </Col>
            <Col md="auto">
              <Button
                className="main-action-button modal-button-margin"
                onClick={saveHDPNGFile}
              >
                PNG without background
              </Button>{" "}
            </Col>
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PFPModal;
