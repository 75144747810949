import ProgressBar from "./ProgressBar";
import { Row, Col, Image } from "react-bootstrap";
import { useCallback, useState } from "react";
import StakedImg from "../assets/unstake.svg";
import StakeddImg from "../assets/staked-small.svg";

function FlowtysList({ flowtys, morphys, mla, limit, didSelect, filter }) {
  const [selected, setSelected] = useState([]);

  const callbackFunction = useCallback(
    (selected) => {
      if (didSelect) didSelect(selected);
    },
    [didSelect]
  );

  const itemSelected = (e, item) => {
    var cloned = [...selected];
    if (cloned.includes(item.name)) {
      cloned = cloned.filter((el) => el !== item.name);
    } else {
      cloned.push(item.name);
    }
    if (cloned.length < limit) {
      setSelected(cloned);
    }

    const all = [].concat.apply([], flowtys).concat(morphys).concat(mla);
    callbackFunction(
      all
        .filter((el) => cloned.includes(el.name))
        .map((el) => (filter ? filter(el) : el))
    );
  };

  return (
    <>
      {(flowtys || []).map((item, index) => {
        return (
          <Col
            md={3}
            lg={2}
            key={`col_${index}`}
            className="justify-content-md-center mr-2 ml-2 mb-5 col-flowtys"
          >
            <Row className="justify-content-center align-items-center content">
              <ProgressBar percent={item.ageLevelPercentage} />
              <div className="staked-ind">
                <Image
                  className="stake-img-center"
                  src={StakeddImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <div
                className={
                  selected.includes(item.name)
                    ? "content-overlay-visible overlay-red"
                    : "content-overlay overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              >
                <Image
                  className="stake-img-center"
                  src={StakedImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <Image
                className="flowty-img"
                src={item.image}
                alt="loading..."
                roundedCircle
                onClick={(e) => {
                  itemSelected(e, item);
                }}
                crossOrigin="anonymous"
              />
              <div
                hidden={!selected.includes(item.name)}
                className={
                  selected.includes(item.name)
                    ? "content-details-visible overlay-red"
                    : "content-details overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              ></div>
            </Row>
            <Row className="justify-content-center align-items-center ">
              <div className="flowty-info">
                {item.name.replace("Flowty", "")}
              </div>
            </Row>
            <Row className="justify-content-center align-items-center ">
              <div className="flowty-info">{item.ageInDays} days old</div>
            </Row>
          </Col>
        );
      })}
      {(mla || []).map((item, index) => {
        return (
          <Col
            md={3}
            lg={2}
            key={`col_${index}`}
            className="justify-content-md-center mr-2 ml-2 mb-5 col-flowtys"
          >
            <Row className="justify-content-center align-items-center content">
              <div className="staked-ind">
                <Image
                  className="stake-img-center"
                  src={StakeddImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <div
                className={
                  selected.includes(item.name)
                    ? "content-overlay-visible overlay-red"
                    : "content-overlay overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              >
                <Image
                  className="stake-img-center"
                  src={StakedImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <Image
                className="flowty-img"
                src={item.image}
                alt="loading..."
                roundedCircle
                onClick={(e) => {
                  itemSelected(e, item);
                }}
                crossOrigin="anonymous"
              />
              <div
                hidden={!selected.includes(item.name)}
                className={
                  selected.includes(item.name)
                    ? "content-details-visible overlay-red"
                    : "content-details overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              ></div>
            </Row>
            <Row className="justify-content-center align-items-center ">
              <div className="flowty-info">
                {item.name.replace("Flowty", "")}
              </div>
            </Row>
          </Col>
        );
      })}
      {(morphys || []).map((item, index) => {
        return (
          <Col
            md={3}
            lg={2}
            key={`col_${index}`}
            className="justify-content-md-center mr-2 ml-2 mb-5 col-flowtys"
          >
            <Row className="justify-content-center align-items-center content">
              <ProgressBar
                percent={100}
                strokeColor={item.name.includes("🎃") ? "#DA780B" : "#E83e62"}
              />
              <div className="staked-ind">
                <Image
                  className="stake-img-center"
                  src={StakeddImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <div
                className={
                  selected.includes(item.name)
                    ? "content-overlay-visible overlay-red"
                    : "content-overlay overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              >
                <Image
                  className="stake-img-center"
                  src={StakedImg}
                  alt="loading..."
                  crossOrigin="anonymous"
                />
              </div>
              <Image
                className="flowty-img"
                src={item.image
                  .replace(".gif", ".jpg")
                  .replace("metadata.flowtys.com", "studio.flowtys.com")
                  .replace(
                    "/images/",
                    item.name.includes("🎃")
                      ? "/halloween_gen_sd/images/"
                      : "/christmas_gen_sd/images/"
                  )}
                alt="loading..."
                roundedCircle
                onClick={(e) => {
                  itemSelected(e, item);
                }}
                crossOrigin="anonymous"
              />
              <div
                hidden={!selected.includes(item.name)}
                className={
                  selected.includes(item.name)
                    ? "content-details-visible overlay-red"
                    : "content-details overlay-red"
                }
                onClick={(e) => {
                  itemSelected(e, item);
                }}
              ></div>
            </Row>
            <Row className="justify-content-center align-items-center ">
              <div className="flowty-info">
                {item.name.replace("Flowty", "")}
              </div>
            </Row>
          </Col>
        );
      })}
    </>
  );
}

export default FlowtysList;
