import crossBig from "../assets/cross-big.svg";
import { useState } from "react";
import { Row, Button, Modal, Image } from "react-bootstrap";
import { saveAs } from "file-saver";
import loadingGif from "../assets/loading_gif.gif";
const { v4: uuidv4 } = require("uuid");

function PromoGif(props) {
  const [isLoading, setLoading] = useState(false);
  const [shouldStop, setStop] = useState(false);
  const [promoFile, setPromoUrl] = useState(false);

  const sleep = (milliseconds) => {
    return new Promise((resolve) => setTimeout(resolve, milliseconds));
  };

  async function exists() {
    const out = `promo/${uuidv4()}.gif`;
    const url = `https://studio.flowtys.com/${out}`;
    const params = {
      folders: props.folders,
      username: props.username || "",
      format: "promogif",
      out,
    };
    const urlSQS = `${
      process.env.REACT_APP_AWS_SQS_BASE
    }?Action=SendMessage&MessageBody=${encodeURIComponent(
      JSON.stringify(params)
    )}`;
    await fetch(urlSQS, { method: "GET" });
    let result = null;
    setLoading(true);
    setStop(false);
    while (true) {
      sleep(2000);
      if (shouldStop || !props.show) {
        return;
      }
      try {
        result = await fetch(url, { method: "HEAD" });
        if (result && result.ok) {
          setLoading(false);
          setPromoUrl(url);
          return;
        }
      } catch {}
    }
  }

  const close = () => {
    setStop(true);
  };

  const saveFile = (event) => {
    event.preventDefault();
    saveAs(promoFile, "flowtys_promo.gif");
  };
  return (
    <Modal
      {...props}
      size="lg"
      onShow={exists}
      onExit={close}
      className="special_modal align-items-center justify-content-center"
      aria-labelledby="contained-modal-title-vcenter"
      dialogClassName="sale-modal modal-60h modal-90w"
      centered
    >
      <Modal.Header>
        <div className="float-right">
          <img
            alt=""
            style={{ transition: "none" }}
            src={crossBig}
            className="Cross-big float-right"
            onClick={() => {
              setStop(true);
              props.setShow(false);
            }}
          />
        </div>
        <Row className="align-items-center justify-content-center w-100">
          <Button
            disabled={isLoading}
            className="main-action-button download-button"
            onClick={saveFile}
          >
            {isLoading ? "Generating…" : "Download"}
          </Button>{" "}
        </Row>
      </Modal.Header>
      <Modal.Body>
        <Row className="align-items-center justify-content-center h-100">
          <Row className="align-items-center justify-content-center">
            <Image
              hidden={!isLoading}
              className="ratio-1x1 promo-image"
              src={loadingGif}
              alt="loading..."
              rounded
            />
          </Row>
          <Row className="align-items-center justify-content-center">
            <Image
              hidden={isLoading}
              className="ratio-1x1 promo-image"
              src={promoFile}
              alt="loading..."
              rounded
            />
          </Row>
        </Row>
      </Modal.Body>
    </Modal>
  );
}

export default PromoGif;
